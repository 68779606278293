<template>
  <div :class="{ 'top-border': hasTopBorder }" class="money-card">
    <svg-icon v-if="hasIcon" class="balance__icon" :name="iconName"></svg-icon>
    <div class="money-card__title">
      <h4>{{ title }}</h4>
      <div
        v-if="hasInfo"
        v-tooltip.right="{
          content: infoText,
          offset: 4,
        }"
        class="money-card__subtitle-info"
      >
        <svg-icon
          v-if="hasInfo"
          name="input-info"
          class="money-card__title-icon"
        ></svg-icon>
      </div>
    </div>
    <div class="money-card__numbers">
      <h2 class="money-card__numbers-main">
        {{ valueMain }}
      </h2>
      <!-- <div
        v-if="valueSecondary"
        class="money-card__numbers-secondary"
        :class="{
          'value-positive': valueSecondary > 0,
          'value-negative': valueSecondary < 0,
        }"
      >
        <p>{{ valueSecondary }}</p>
        <svg-icon v-if="valueSecondary > 0" name="arrow_up"></svg-icon>
        <svg-icon v-if="valueSecondary < 0" name="arrow_down"></svg-icon>
      </div> -->
    </div>
    <div v-if="subtitle" class="money-card__subtitle subtitle">
      <p>{{ subtitle }}</p>
      <div
        v-if="isShowSlider"
        v-tooltip.left="{
          content:
            'Сhoose the percentage of income you want to reinvest on your monthly balance.  You can always change the percentage.',
          offset: 4,
        }"
        class="money-card__subtitle-info"
      >
        <svg-icon
          name="input-info"
          class="money-card__subtitle-icon"
        ></svg-icon>
      </div>
    </div>
    <RangeSlider
      v-if="isShowSlider"
      :current-compound-percent="currentCompoundPercent"
      @change="changePercent"
    />
  </div>
</template>

<script>
import RangeSlider from '@/elements/RangeSlider';

export default {
  components: { RangeSlider },
  props: {
    title: {
      type: String,
      default: '',
    },
    valueMain: {
      type: String,
      default: '0',
    },
    iconName: {
      type: String,
      default: '0',
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    hasInfo: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: '',
    },
    valueSecondary: {
      type: String,
      default: '0',
    },
    hasTopBorder: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    isShowSlider: {
      type: Boolean,
      default: false,
    },
    currentCompoundPercent: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changePercent(percent) {
      this.$emit('change-percent', percent);
    },
  },
};
</script>

<style lang="sass" scoped>
.money-card
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 0.8rem
  padding: 2.4rem

.money-card__title
  display: flex
  gap: 1rem
  align-items: center
  & svg
    max-width: 1.6rem
    max-height: 1.6rem

.money-card__numbers
  display: flex
  align-items: center
  gap: 2.4rem

.top-border
  border-top: 0.5rem solid $primary-color-blue

// .money-card__numbers-main::before
//   content: '$'
//   white-space: nowrap

.money-card__numbers-secondary
  display: flex
  align-items: center
  justify-content: center
  border-radius: 0.5rem
  width: 6.5rem
  height: 2.6rem
  & p::after
    content: '%'
    margin-right: 0.4rem
  & svg
    width: 1rem

.value-positive
  background-color: $success-background
  color: $success-text

.value-negative
  background-color: $negative-background
  color: $negative-text

.money-card.compound
  .money-card__subtitle
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 16px
    padding-bottom: 8px

.money-card__subtitle-info
  display: flex
  cursor: pointer

.money-card__subtitle-icon
  width: 16px
  height: 16px
</style>
