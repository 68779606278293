<template>
  <div class="percentage-card">
    <svg-icon class="percentage-card__icon" name="flag"></svg-icon>
    <div class="percentage-card__title">
      <h4>Monthly rewards</h4>
      <div
        v-tooltip.right="{
          content:
            'Your monthy rewards change depending on the amount of your investment',
          offset: 4,
        }"
        class="money-card__subtitle-info"
      >
        <svg-icon name="input-info" class="money-card__title-icon"></svg-icon>
      </div>
    </div>
    <div
      v-for="(item, id) in getDashboardSettings.investment_percentage"
      :key="id"
      class="percentage-card__item"
    >
      <svg-icon v-if="item.from == '0'" name="arrow_left_small"></svg-icon>
      <svg-icon v-if="item.to == '0'" name="arrow_right_small"></svg-icon>
      <h5 v-if="item.from != '0'">{{ item.from_formatted }}</h5>
      <span v-if="item.from != '0' && item.to != '0'">-</span>
      <h5 v-if="item.to != '0'">{{ item.to_formatted }}</h5>
      <h5>&nbsp;- {{ item.percent }}%</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {},
  computed: {
    ...mapGetters(['getDashboardSettings']),
  },
};
</script>

<style lang="sass" scoped>
.percentage-card
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 0.8rem
  padding: 2.4rem
  border-top: 0.5rem solid $primary-color-blue


.percentage-card__title
  display: flex
  gap: 1rem
  align-items: center
  & svg
    max-width: 1.6rem
    max-height: 1.6rem

.percentage-card__icon
  max-height: 2.4rem
  max-width: 2.4rem

.percentage-card__item
  display: flex
  align-items: center
  & svg
    height: 1.2rem
    width: 1.2rem
    margin-right: 0.5rem
</style>
