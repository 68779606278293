var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"money-card",class:{ 'top-border': _vm.hasTopBorder }},[(_vm.hasIcon)?_c('svg-icon',{staticClass:"balance__icon",attrs:{"name":_vm.iconName}}):_vm._e(),_c('div',{staticClass:"money-card__title"},[_c('h4',[_vm._v(_vm._s(_vm.title))]),(_vm.hasInfo)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
        content: _vm.infoText,
        offset: 4,
      }),expression:"{\n        content: infoText,\n        offset: 4,\n      }",modifiers:{"right":true}}],staticClass:"money-card__subtitle-info"},[(_vm.hasInfo)?_c('svg-icon',{staticClass:"money-card__title-icon",attrs:{"name":"input-info"}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"money-card__numbers"},[_c('h2',{staticClass:"money-card__numbers-main"},[_vm._v(" "+_vm._s(_vm.valueMain)+" ")])]),(_vm.subtitle)?_c('div',{staticClass:"money-card__subtitle subtitle"},[_c('p',[_vm._v(_vm._s(_vm.subtitle))]),(_vm.isShowSlider)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
        content:
          'Сhoose the percentage of income you want to reinvest on your monthly balance.  You can always change the percentage.',
        offset: 4,
      }),expression:"{\n        content:\n          'Сhoose the percentage of income you want to reinvest on your monthly balance.  You can always change the percentage.',\n        offset: 4,\n      }",modifiers:{"left":true}}],staticClass:"money-card__subtitle-info"},[_c('svg-icon',{staticClass:"money-card__subtitle-icon",attrs:{"name":"input-info"}})],1):_vm._e()]):_vm._e(),(_vm.isShowSlider)?_c('RangeSlider',{attrs:{"current-compound-percent":_vm.currentCompoundPercent},on:{"change":_vm.changePercent}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }