<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <div class="chart-card__header-block">
        <div class="chart-card__title">
          <h4>{{ title }}</h4>
        </div>
        <div class="chart-card__numbers">
          <h2 class="chart-card__numbers-main">
            {{ valueMain }}
          </h2>
        </div>
      </div>
      <!-- <div v-if="referral" class="chart-card__button">
        <ButtonBase :disabled="!hasReferrals" @click="referralsAll()"
          >Referrals</ButtonBase
        >
      </div> -->
    </div>
    <div class="chart-card__subtitle subtitle">
      <p>{{ subtitle }}</p>
    </div>
    <div class="chart-card__chart">
      <line-chart
        class="chart"
        :height="180"
        :chartdata="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import LineChart from '@/elements/LineChart.vue';

export default {
  components: { LineChart },
  props: {
    chartDataX: {
      type: Array,
      default: () => [],
    },
    chartDataValues: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    referral: {
      type: Boolean,
      default: false,
    },
    hasReferrals: {
      type: Boolean,
      default: false,
    },
    valueMain: {
      type: String,
      default: '0',
    },
    valueSecondary: {
      type: String,
      default: '0',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    const vm = this;
    return {
      chartData: {
        labels: this.chartDataX,
        datasets: [
          {
            backgroundColor: '#F4FDFF',
            data: this.chartDataValues,
            borderColor: '#00C3FF',
            pointRadius: 0,
            pointHitRadius: 20,
            bezierCurve: false,
          },
        ],
      },
      chartOptions: {
        // onHover: (event, chartElement) => {
        //   if (this.referral) {
        //     event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        //   }
        // },
        // onClick: function (event, array) {
        //   if (array.length != 0 && vm.referral) {
        //     var position = array[0]._index;
        //     var activeElement = this.tooltip._data.datasets[0].data[position];
        //     vm.$store.commit('modal/SET_MODAL', {
        //       state: true,
        //       name: 'referrals-all',
        //       props: vm.chartDataX[position],
        //     });
        //   }
        // },
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function (t, d) {
              return '$' + t[0].value;
            },
            label: function (t, d) {
              return t.label;
            },
          },
        },
        scales: {
          yAxes: [
            {
              display: false,
            },
          ],
        },
      },
    };
  },
  methods: {
    referralsAll() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'referrals-all',
      });
    },
    // handleChartClick(evt, elements) {
    //   var chart = this.$refs.periodChart.$data._chart;
    //   const chartIndex = chart.getElementAtEvent(evt);
    //   if (chartIndex.length !== 0) {
    //     const datasetIndex = chartIndex[0]._datasetIndex;
    //     const position = chartIndex[0]._index;
    //     const info = {
    //       datasetIndex: datasetIndex,
    //       valueIndex: position,
    //       label: chart.tooltip._data.labels[position],
    //       value: chart.tooltip._data.datasets[datasetIndex].data[position],
    //     };
    //     console.log(info);
    //   } else {
    //     console.log('Background clicked');
    //   }
    // },
  },
};
</script>

<style lang="sass" scoped>
.chart-card
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 0.8rem
  padding: 2.4rem
  height: 36rem

.chart-card__numbers
  display: flex
  align-items: center
  gap: 2.4rem

.chart-card__header
  display: flex
  justify-content: space-between

.chart-card__numbers-secondary
  display: flex
  align-items: center
  justify-content: center
  border-radius: 0.5rem
  width: 6.5rem
  height: 2.6rem
  & p::after
    content: '%'
    margin-right: 0.4rem
  & svg
    width: 1rem

.value-positive
  background-color: $success-background
  color: $success-text

.value-negative
  background-color: $negative-background
  color: $negative-text

.chart-card__chart
  position: relative
  display: flex
  flex-grow: 1

.chart
  width: 100%
  position: absolute
</style>
