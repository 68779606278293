<template>
  <div v-if="dashboardLoaded" class="dashboard">
    <div class="dashboard__header">
      <h3 class="dashboard__header-title">Dashboard</h3>
      <div class="dashboard__header-buttons">
        <ButtonBase @click="investment()">Invest</ButtonBase>
        <ButtonBase @click="withdrawal()">Withdrawal</ButtonBase>
      </div>
    </div>
    <div class="dashboard__balance-block">
      <MoneyCard
        title="Balance"
        icon-name="balance"
        has-icon
        has-top-border
        subtitle="Total balance of invested funds"
        :value-main="getDashboard.total_balance_of_invested_funds"
      />
<!--      <PercentageInfoCard-->
<!--        :percentage-settings="getDashboardSettings.investment_percentage"-->
<!--      />-->
      <MoneyCard
        title="Assets under management"
        has-icon
        icon-name="total"
        has-top-border
        subtitle="The total amount of all investments"
        :value-main="getDashboardSettings.total_investments_amount_formatted"
      />
    </div>
    <div class="dashboard__top-block">
      <MoneyCard
        title="Final balance"
        has-info
        info-text="Final balance taken into account all deposits, profits & withdrawals"
        :value-main="getDashboard.final_balance"
      />
      <MoneyCard
        title="Amount of income"
        :value-main="getDashboard.amount_of_income"
      />
      <MoneyCard
        title="Monthly income"
        subtitle="Compound"
        class="compound"
        is-show-slider
        :value-main="getDashboard.monthly_income"
        :current-compound-percent="getCurrentUser.compound_percentage"
        @change-percent="changeCompoundPercent"
      />
    </div>
    <div class="dashboard__center-block">
      <ChartCard
        title="Profit chart"
        subtitle="For the month"
        :value-main="getDashboard.profit_chart.amount"
        :chart-data-x="getDashboard.profit_chart.x"
        :chart-data-values="getDashboard.profit_chart.y"
      />
      <ChartCard
        v-if="getDashboard.referral_system_enabled"
        referral
        :has-referrals="getDashboard.has_referrals"
        title="Referral income chart"
        subtitle="By referral link"
        :value-main="getDashboard.referral_income_chart.amount"
        :chart-data-x="getDashboard.referral_income_chart.x"
        :chart-data-values="getDashboard.referral_income_chart.y"
      />
    </div>
    <div class="dashboard__bottom-block">
      <FreezeInfoCard :freeze="getDashboard.freeze" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MoneyCard from '@/elements/MoneyCard.vue';
import PercentageInfoCard from '@/elements/PercentageInfoCard.vue';
import FreezeInfoCard from '@/elements/FreezeInfoCard.vue';
import ChartCard from '@/elements/ChartCard.vue';

export default {
  components: {
    MoneyCard,
    PercentageInfoCard,
    FreezeInfoCard,
    ChartCard,
  },
  data() {
    return {
      dashboardLoaded: false,
    };
  },
  computed: {
    ...mapGetters(['getDashboard', 'getCurrentUser', 'getDashboardSettings']),
  },
  async created() {
    await this.$store.dispatch('getDashboard');
    await this.$store.dispatch('getDashboardSettings');
    this.dashboardLoaded = true;
  },
  methods: {
    withdrawal() {
      var freezeArray = this.getDashboard.freeze.items.filter(function (el) {
        return el.status === 'Available';
      });
      console.log(freezeArray.length != 0);
      if (
        this.getDashboard.available_amount_for_withdrawn &&
        freezeArray.length != 0
      ) {
        this.$store.commit('modal/SET_MODAL', {
          state: true,
          name: 'withdrawal-amount',
          props: {
            availableAmountForWithdrawn:
              this.getDashboard.available_amount_for_withdrawn,
          },
        });
      } else if (
        this.getDashboard.available_amount_for_withdrawn > 0 &&
        freezeArray.length == 0
      ) {
        let props = {};
        if (this.getDashboard.freeze.items.length != 0) {
          props = {
            freezeDataStart:
              this.getDashboard.freeze.items[
                this.getDashboard.freeze.items.length - 1
              ].date_of_investment,
            freezeDataEnd:
              this.getDashboard.freeze.items[
                this.getDashboard.freeze.items.length - 1
              ].account_defrost_state,
            availableAmountForWithdrawn:
              this.getDashboard.available_amount_for_withdrawn,
          };
        }
        this.$store.commit('modal/SET_MODAL', {
          state: true,
          name: 'account-frozen-available',
          props: props,
        });
      } else {
        let props = {};
        if (this.getDashboard.freeze.items.length != 0) {
          props = {
            freezeDataStart:
              this.getDashboard.freeze.items[
                this.getDashboard.freeze.items.length - 1
              ].date_of_investment,
            freezeDataEnd:
              this.getDashboard.freeze.items[
                this.getDashboard.freeze.items.length - 1
              ].account_defrost_state,
            availableAmountForWithdrawn:
              this.getDashboard.available_amount_for_withdrawn,
          };
        }
        this.$store.commit('modal/SET_MODAL', {
          state: true,
          name: 'account-frozen',
          props: props,
        });
      }
    },
    investment() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'investment',
      });
    },
    changeCompoundPercent(percent) {
      this.$store.commit('UPDATE_USER', {
        ...this.getCurrentUser,
        compound_percentage: percent.new,
      });
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'confirm-change-percentage',
        props: {
          percent,
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.dashboard
  padding: 2.4rem
  display: flex
  flex-direction: column
  gap: 2.4rem
  flex: 0 0 100%

.dashboard__header
  display: flex
  justify-content: space-between
  align-items: center
  gap: 2.4rem
  @include m
    flex-direction: column

.dashboard__header-buttons
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    & button
      width: 14rem

.dashboard__header-title
  margin-right: auto

.dashboard__top-block
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    flex-wrap: wrap

.dashboard__balance-block
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    flex-wrap: wrap


.dashboard__bottom-block
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    flex-wrap: wrap

.dashboard__center-block
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    flex-wrap: wrap
</style>
