<template>
  <div class="freeze-card">
    <h4 class="freeze-card__title">Freeze date range</h4>
    <div class="money-card__numbers">
      <h2 class="money-card__numbers-main">{{ freeze.amount }}</h2>
    </div>
    <p class="subtitle">Total balance</p>
    <div class="freeze-table">
      <div class="freeze-table__header">
        <h5 class="subtitle">Status</h5>
        <h5 class="subtitle">Date of investment</h5>
        <h5 class="subtitle">Account defrost date</h5>
        <h5 class="subtitle">Amount</h5>
      </div>
      <div
        v-for="(item, id) in freeze.items"
        :key="id"
        class="freeze-table__item"
      >
        <h5
          :class="{
            available: item.status === 'Available',
            frozen: item.status === 'Frozen',
          }"
        >
          {{ item.status }}
        </h5>
        <h5>
          <span class="hidden-title">Date of investment: </span
          >{{ item.date_of_investment }}
        </h5>
        <h5>
          <span class="hidden-title">Account defrost date: </span
          >{{ item.account_defrost_state }}
        </h5>
        <h5><span class="hidden-title">Amount: </span>{{ item.amount }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    freeze: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="sass" scoped>
.freeze-card
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 0.8rem
  padding: 2.4rem
  border-top: 0.5rem solid $primary-color-blue

.freeze-card-frozen
  background-color: $primary-background

.freeze-card__bottom
  display: flex
  align-items: center
  gap: 1.8rem

.freeze-card__icon
  max-height: 3.4rem
  max-width: 3.4rem

.money-card__numbers
  display: flex
  align-items: center
  gap: 2.4rem

// .money-card__numbers-main::before
//   content: '$'

.money-card__numbers-secondary
  display: flex
  align-items: center
  justify-content: center
  border-radius: 0.5rem
  width: 6.5rem
  height: 2.6rem
  background-color: $success-background
  color: $success-text
  & p::after
    content: '%'
    margin-right: 0.4rem
  & svg
    width: 1rem

.arrow__icon
  max-width: 2.4rem

.freeze-table
  margin: 1.2rem 0

.freeze-table__header
  display: grid
  grid-template-columns: repeat(4, 1fr)
  height: 4.8rem
  padding: 1.2rem 2.4rem
  align-items: center
  margin-top: 0.6rem
  @include m
    display: none

.freeze-table__item
  display: grid
  grid-template-columns: repeat(4, 1fr)
  height: 4.8rem
  padding: 1.2rem 2.4rem
  align-items: center
  margin-top: 0.6rem
  @include m
    display: flex
    flex-direction: column
    height: auto
    padding: 2.4rem 2.4rem
    align-items: start
    gap: 1rem
    & :not(:first-child)
      font-size: 1.2rem

.hidden-title
  display: none
  color: $subtitle-color
  @include m
    display: inline

.freeze-table__item
  background: $background-color

.available
  color: $success-text

.frozen
  color: $primary-color-blue
</style>
