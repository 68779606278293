<template>
  <v-slider
    v-model="compoundPercent"
    thumb-color="#FFFFFF"
    tick-size="0"
    :tick-labels="ticksLabels"
    step="25"
    track-color="#f4fdff"
    track-fill-color="#00C3FF"
    @change="onChange"
  ></v-slider>
</template>

<script>
export default {
  props: {
    currentCompoundPercent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      compoundPercent: 0,
      ticksLabels: ['0%', '25%', '50%', '75%', '100%'],
    };
  },
  watch: {
    currentCompoundPercent() {
      this.updatePercent();
    },
  },
  created() {
    this.updatePercent();
  },
  methods: {
    updatePercent() {
      this.compoundPercent = this.currentCompoundPercent;
    },
    onChange() {
      if (this.currentCompoundPercent === this.compoundPercent) return;

      this.$emit('change', {
        old: this.currentCompoundPercent,
        new: this.compoundPercent,
      });
    },
  },
};
</script>

<style lang="sass">
.v-slider__thumb
  box-shadow: 0px 1.74545px 1.74545px rgba(50, 50, 71, 0.06), 0px 1.74545px 3.49091px rgba(50, 50, 71, 0.06)
  cursor: pointer

  &:after
    width: 13px !important
    height: 13px !important
    background-color: #00C3FF
    border-radius: 50%

  &:before
    display: none

.v-slider__tick-label
  font-size: 1.2rem
  line-height: 1.8rem

.v-slider--horizontal .v-slider__tick
  .v-slider__tick-label
    top: -26px !important

.v-slider--horizontal .v-slider__tick:nth-child(2),
.v-slider--horizontal .v-slider__tick:nth-child(3),
.v-slider--horizontal .v-slider__tick:nth-child(4)
  .v-slider__tick-label
    left: -10px

.v-slider--horizontal .v-slider__tick:nth-child(5)
  .v-slider__tick-label
    left: -24px

.theme--light.v-messages
  display: none
</style>
